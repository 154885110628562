import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e32718e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "config-preset-container" }
const _hoisted_2 = { class: "config-presets" }
const _hoisted_3 = { class: "config-preset-settings" }
const _hoisted_4 = ["value", "disabled"]
const _hoisted_5 = ["value", "disabled"]
const _hoisted_6 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerticalSlideTransition = _resolveComponent("VerticalSlideTransition")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.settingsType = 'RectangleSettings'))
        }, "Rectangle"),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.settingsType = 'CylinderCurveSettings'))
        }, "Cylinder Curve"),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.settingsType = null))
        }, "Custom")
      ]),
      (_openBlock(), _createBlock(_KeepAlive, null, [
        _createVNode(_component_VerticalSlideTransition, {
          showIf: _ctx.settingsType !== null,
          hide: true,
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.settingsType), {
                  imageWidth: _ctx.imageWidth,
                  imageHeight: _ctx.imageHeight,
                  "onUpdate:xExpression": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:xExpression', $event))),
                  "onUpdate:yExpression": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:yExpression', $event))),
                  "onUpdate:zExpression": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:zExpression', $event))),
                  onUpdatePreview: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('updatePreview')))
                }, null, 40, ["imageWidth", "imageHeight"]))
              ], 1024))
            ])
          ]),
          _: 1
        }, 8, ["showIf"])
      ], 1024))
    ]),
    _createElementVNode("label", null, [
      _createTextVNode(" X Expression: "),
      _createElementVNode("input", {
        value: _ctx.xExpression,
        disabled: _ctx.settingsType !== null,
        onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:xExpression', $event.target.value))),
        onFocusout: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('updatePreview')))
      }, null, 40, _hoisted_4)
    ]),
    _createElementVNode("label", null, [
      _createTextVNode(" Y Expression: "),
      _createElementVNode("input", {
        value: _ctx.yExpression,
        disabled: _ctx.settingsType !== null,
        onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('update:yExpression', $event.target.value))),
        onFocusout: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('updatePreview')))
      }, null, 40, _hoisted_5)
    ]),
    _createElementVNode("label", null, [
      _createTextVNode(" Z Expression: "),
      _createElementVNode("input", {
        value: _ctx.zExpression,
        disabled: _ctx.settingsType !== null,
        onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('update:zExpression', $event.target.value))),
        onFocusout: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('updatePreview')))
      }, null, 40, _hoisted_6)
    ])
  ], 64))
}