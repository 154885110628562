import { renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a9e58ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "el",
  class: "slide-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "slide-transition" }, {
    default: _withCtx(() => [
      (_ctx.showIf || _ctx.hide)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ], 512)), [
            [_vShow, _ctx.showIf || !_ctx.hide]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}