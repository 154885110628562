import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a403d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  open: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, {
    to: _ctx.teleportTo,
    disabled: _ctx.noTeleport
  }, [
    _createVNode(_Transition, {
      appear: "",
      name: "modal-slide-fade"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("dialog", _hoisted_1, [
              _createElementVNode("article", null, [
                _createElementVNode("header", null, [
                  _createElementVNode("h3", null, [
                    _renderSlot(_ctx.$slots, "title", {}, undefined, true)
                  ]),
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn-close-icon",
                    "aria-label": "Close",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
                  })
                ]),
                _createElementVNode("main", null, [
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _createElementVNode("footer", null, [
                  _renderSlot(_ctx.$slots, "footer", {}, () => [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn-close",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
                    }, "Close")
                  ], true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 8, ["to", "disabled"]))
}