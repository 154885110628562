
import { defineComponent, PropType } from "vue";
import { Renderer, Camera, Scene, AmbientLight, PointLight } from "troisjs";
import AxesHelper from "./AxesHelper";
import StlMesh from "./StlMesh";
import { ColorRepresentation } from "three";

export default defineComponent({
	"components": {
		Renderer,
		Camera,
		Scene,
		AmbientLight,
		PointLight,
		StlMesh,
		AxesHelper,
	},
	"props": {
		"stl": { "type": Object as PropType<Uint8Array|null> },
		"color": { "type": String as PropType<ColorRepresentation>, "default": "#ffffff" },
		"doubleSided": { "type": Boolean as PropType<boolean>, "default": false },
	},
	"computed": {
		stlBuffer(): ArrayBuffer|null {
			return (typeof this.stl === "undefined" || this.stl === null)
				? null
				: this.stl.buffer.slice(this.stl.byteOffset, this.stl.byteLength + this.stl.byteOffset);
		},
	},
});
