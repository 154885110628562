import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stl-viewer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Camera = _resolveComponent("Camera")!
  const _component_PointLight = _resolveComponent("PointLight")!
  const _component_AmbientLight = _resolveComponent("AmbientLight")!
  const _component_StlMesh = _resolveComponent("StlMesh")!
  const _component_AxesHelper = _resolveComponent("AxesHelper")!
  const _component_Scene = _resolveComponent("Scene")!
  const _component_Renderer = _resolveComponent("Renderer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Renderer, {
      orbitCtrl: "",
      resize: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Camera, { position: { x: 100, y: 100, z: 100 } }),
        _createVNode(_component_Scene, { background: "#000000" }, {
          default: _withCtx(() => [
            _createVNode(_component_PointLight, {
              color: "#ffffff",
              position: { x: 500000, y: 500000, z: 500000 },
              intensity: .25
            }),
            _createVNode(_component_PointLight, {
              color: "#ffffff",
              position: { x: 500000, y: -500000, z: 500000 },
              intensity: .25
            }),
            _createVNode(_component_PointLight, {
              color: "#ffffff",
              position: { x: -500000, y: 500000, z: 500000 },
              intensity: .25
            }),
            _createVNode(_component_PointLight, {
              color: "#ffffff",
              position: { x: -500000, y: -500000, z: 500000 },
              intensity: .25
            }),
            _createVNode(_component_AmbientLight, { color: "#202020" }),
            (_ctx.stlBuffer !== null)
              ? (_openBlock(), _createBlock(_component_StlMesh, {
                  key: [_ctx.stlBuffer, _ctx.color, _ctx.doubleSided],
                  model: _ctx.stlBuffer,
                  color: _ctx.color,
                  doubleSided: _ctx.doubleSided
                }, null, 8, ["model", "color", "doubleSided"]))
              : _createCommentVNode("", true),
            _createVNode(_component_AxesHelper, { size: 1000000 })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}