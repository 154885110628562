
import { defineComponent, nextTick, PropType } from "vue";
import RectangleSettings from "./settings/RectangleSettings.vue";
import CylinderCurveSettings from "./settings/CylinderCurveSettings.vue";
import VerticalSlideTransition from "./VerticalSlideTransition.vue";

const settingsTypes = {
	RectangleSettings,
	CylinderCurveSettings,
} as const;

export default defineComponent({
	"components": {
		...settingsTypes,
		VerticalSlideTransition,
	},
	"props": {
		"xExpression": { "type": String as PropType<string>, "default": "" },
		"yExpression": { "type": String as PropType<string>, "default": "" },
		"zExpression": { "type": String as PropType<string>, "default": "" },
		"imageWidth": { "type": Number as PropType<number> },
		"imageHeight": { "type": Number as PropType<number> },
	},
	data() {
		return {
			"settingsType": "RectangleSettings" as keyof typeof settingsTypes|null,
		};
	},
	"emits": [
		"update:xExpression",
		"update:yExpression",
		"update:zExpression",
		"updatePreview",
	],
	"watch": {
		async settingsType(): Promise<void> {
			await nextTick();
			this.$emit("updatePreview");
		},
	},
});
