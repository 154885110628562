
import { assert } from "@/util";
import { defineComponent, nextTick } from "vue";

const component = defineComponent({
	"props": {
		"showIf": { "type": Boolean, "required": true },
		"hide": { "type": Boolean, "default": false }, // Hide instead of removing
	},
	"watch": {
		async showIf(shown: boolean) {
			if (shown) {
				// Wait for height to be calculated
				await nextTick();
			}
			const el = this.$refs.el;
			assert(el instanceof HTMLDivElement);
			el.style.setProperty("--transition-height", `${el.scrollHeight}px`);
		},
	},
});

export default component;
