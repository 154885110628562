
import { defineComponent } from "vue";

export default defineComponent({
	"props": {
		"teleportTo": { "type": String, "default": "body" },
		"noTeleport": { "type": Boolean, "default": false },
		"show": { "type": Boolean, "default": false },
	},
	"emits": [
		"close",
	],
});
