import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0767390c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "generator" }
const _hoisted_2 = { class: "settings" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "generator-btns" }
const _hoisted_5 = ["href", "download"]
const _hoisted_6 = {
  key: 1,
  type: "button",
  disabled: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GeneratorSettings = _resolveComponent("GeneratorSettings")!
  const _component_StlViewer = _resolveComponent("StlViewer")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, [
          _createTextVNode(" Image "),
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadFile && _ctx.loadFile(...args))),
            accept: ".jpeg,.jpg,.png,.gif"
          }, null, 32)
        ]),
        _createVNode(_component_GeneratorSettings, {
          xExpression: _ctx.xExpression,
          "onUpdate:xExpression": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.xExpression) = $event)),
          yExpression: _ctx.yExpression,
          "onUpdate:yExpression": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.yExpression) = $event)),
          zExpression: _ctx.zExpression,
          "onUpdate:zExpression": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.zExpression) = $event)),
          onUpdatePreview: _cache[4] || (_cache[4] = ($event: any) => {_ctx.generatePreview(100); _ctx.maybeShowLithophane()}),
          imageWidth: _ctx.imageWidth,
          imageHeight: _ctx.imageHeight
        }, null, 8, ["xExpression", "yExpression", "zExpression", "imageWidth", "imageHeight"]),
        _createElementVNode("label", null, [
          _createTextVNode(" File Size: "),
          _createElementVNode("input", {
            disabled: "",
            value: _ctx.lithophaneSize === null ? null : _ctx.prettyBytes(_ctx.lithophaneSize)
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.generateLithophane && _ctx.generateLithophane(...args)))
          }, "Generate Lithophane"),
          (_ctx.showLithophane && _ctx.lithophaneUrl !== null)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                role: "button",
                href: _ctx.lithophaneUrl,
                download: `${_ctx.file?.name.split('.').slice(0, -1).join('.')}.stl`
              }, " Download Lithophane ", 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("button", _hoisted_6, "Download Lithophane"))
        ])
      ]),
      _createVNode(_component_StlViewer, {
        class: "model-viewer",
        stl: _ctx.stlModel,
        color: _ctx.stlColor,
        doubleSided: _ctx.stlDoubleSided
      }, null, 8, ["stl", "color", "doubleSided"])
    ]),
    _createVNode(_component_ModalWindow, {
      show: _ctx.showSizeWarningModal,
      onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showSizeWarningModal = false))
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Large Image ")
      ]),
      body: _withCtx(() => [
        _createTextVNode(" Large images can cause the lithophane to take much longer to generate, and can increase the STL file size significantly. Using higher resolution images for lithophanes quickly runs into diminishing returns, since 3D printers can only achieve so much detail. Consider lowering the resolution of your image before using it to generate a lithophane. ")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}